import FlipClock from './clock/FlipClock'
import dayjs from 'dayjs'

const Timer = ({ time, finished }) => {
    return (
        <div className="mt-16">
            <h1 className="text-center text-xl lg:text-3xl font-bold">Spotkanie {dayjs(time).format('DD MMMM YYYY')} roku o godzinie {dayjs(time).format('HH:mm')}.</h1>
            <div className="w-full sm:w-96 mx-auto mt-10">
                {/* <Flip /> */}
                {time && <FlipClock finished={finished} type="countdown" count_to={time} units={[
                    {
                        sep: '',
                        type: 'days',
                        title: 'dni',
                    },
                    {
                        sep: ' ',
                        type: 'hours',
                        title: 'godziny',
                    },
                    {
                        sep: ':',
                        type: 'minutes',
                        title: 'minuty',
                    },
                    {
                        sep: ':',
                        type: 'seconds',
                        title: 'sekundy',
                    }
                ]} />}
            </div>
        </div>
    )
}

export default Timer