import { useState } from "react"
import ReactPlayer from "react-player"
import Button from "../button/button"
import AskQuestion from "./ask_question/ask_question"

const Stream = () => {
    const [showQuestionForm, toggleQuestionForm] = useState(false)
    const [questionSent, setQuestionSent] = useState(false)
    return (
        <div className="mt-16 ">
            <div className="video-container mx-auto">
                <div className="video bg-gray-200 relative">
                    <iframe className="absolute left-0 top-0 w-full h-full" width="100%" height="100%" src="https://www.youtube.com/embed/h46xJdzfJ44?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    {/* <ReactPlayer url="https://www.youtube.com/embed/h46xJdzfJ44?autoplay=1" muted={true} className="absolute left-0 top-0 w-full h-full" /> */}
                </div>
            </div>
            {!showQuestionForm && <Button text="zadaj pytanie Jurkowi" action={toggleQuestionForm} value={showQuestionForm} />}
            {showQuestionForm && <AskQuestion toggle={toggleQuestionForm} setQuestionSent={setQuestionSent} />}
            {(!showQuestionForm && questionSent) && <p className="text-center mt-4 font-medium">Pytanie wysłane</p>}
        </div>
    )
}

export default Stream;