const Button = ({ text = "button", loading = false, action, value }) => {
    return (
        <button className={`mx-auto block ${loading ? 'bg-blue-600' : 'bg-blue-500'} w-60 text-white text-lg rounded-full font-medium mt-6 hover:bg-blue-600 duration-200 outline-none border-none active:outline-none focus:outline-none h-10 leading-10`} onClick={() => action(!value)}>
            {loading ? <div className="w-8 h-8 mx-auto">
                <div className="loader"></div>
            </div> : text}
        </button>
    )
}

export default Button