import axios from "axios"
import dayjs from "dayjs"
import { useReducer, useState } from "react"
import Button from "../button/button"
import { setName as setNameReducer } from '../../app/user/userSlice'
import { useDispatch } from "react-redux"

const NameInput = ({ toggleStream, time, code }) => {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [nameEmpty, setNameEmpty] = useState(false)

    const submitName = async () => {
        if (name) {
            // const { status, data } = await axios.post('codes/check', {
            //     code,
            //     name
            // }, {
            //     headers: {
            //         'Content-type': 'application/json'
            //     }
            // })
            // console.log(status, data)
            // if (status === 200) {
            //     dispatch(setNameReducer(name))
            //     toggleStream(true)
            // }
            toggleStream(true)
        }
        else
            setNameEmpty(true)
    }
    return (
        <div className="mt-24">
            <h1 className="text-center  text-xl lg:text-3xl font-bold">Spotkanie zaczyna się o godzinie {dayjs(time).format('HH:mm')}</h1>
            <div className="flex justify-center items-center mt-10">
                <span className="text-lg px-4">wpisz imię</span>
                <div className="flex-col flex">
                    <input type="text" className={`bg-gray-200 text-lg px-2 py-2 w-56 outline-none ${nameEmpty && 'border-2 border-red-200'}`} value={name} onChange={(e) => setName(e.target.value)} onClick={() => setNameEmpty(false)} />
                    {nameEmpty && <span className="text-sm text-gray-600">Wpisz imię</span>}
                </div>
            </div>
            <Button text="wejdź" action={submitName} />
        </div>
    )
}

export default NameInput
