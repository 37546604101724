import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import cookies_icon from '../../assets/images/cookie.png'

const Cookies = () => {
    const [showCookies, toggleCookies] = useState(false)

    const [cookies, setCookies] = useCookies(['cookie-consent'])

    useEffect(() => {
        console.log(cookies)
        if (!cookies['cookie-consent']) {
            console.log("Ni ma")
            toggleCookies(true)
        }
    }, [])

    const setAgree = () => {
        toggleCookies(false)
        setCookies('cookie-consent', true, { expires: new Date(Date.now() + (100 * 365 * 24 * 60 * 60 * 1000)) })
    }

    return (
        <div className={`w-full fixed ${showCookies ? 'bottom-0' : '-bottom-full'} duration-200 left-0 border-t-2 border-gray-300 lg:h-40 bg-white cookies-container`}>
            <div className="w-full xl:w-3/4 2xl:w-3/5 h-full mx-auto relative py-10">
                <img src={cookies_icon} alt="" className="absolute left-4 xl:left-0 -top-8 lg:-top-12 w-16 lg:w-24" />
                <div className="absolute right-4 xl:right-0 top-1 text-red-500 font-serif text-xl cursor-pointer" onClick={() => toggleCookies(false)}>&#10006;</div>
                <div className="flex items-end flex-col md:flex-row">
                    <p className="pl-4 lg:pl-32 text-gray-500 text-md">Ta strona wykorzystuje pliki cookie. Dowiedz się więcej o celu ich używania i zmianach ustawień z <a href="https://google.com" className="text-gray-800 font-medium underline">polityki prywatności</a> dostępnej na tej stronie. Korzystając ze strony wyrażasz zgodę na używanie cookies, zgodnie z aktualnymi ustawieniami przeglądarki.
                    <br />
                    Więcej informacji znajduję się w <a href="https://google.com" className="text-gray-800 font-medium underline"> polityce prywatności.</a></p>
                    <div className="bg-blue-500 hover:bg-blue-600 duration-200 text-white rounded-full px-8 py-1 font-medium text-lg cursor-pointer mr-4 xl:mr-0" onClick={setAgree}>ok</div>
                </div>
            </div>
        </div>
    )
}

export default Cookies