import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Cookies from './components/cookies/cookies';
import NameInput from './components/name_input/name_input';
import Navbar from './components/navbar/navbar';
import Stream from './components/stream/stream';
import Timer from './components/timer/timer';

import { socket } from './config/websocket'

import axios from 'axios'

import 'dayjs/locale/pl'
import { Route, Switch, useParams } from 'react-router';
import Admin from './components/admin/admin';

dayjs.locale('pl')
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:1337/' : 'tu bedzie link do prod'

function App() {
  const [showStream, toggleStream] = useState(false)
  const [time, setTime] = useState('')
  const [countdownFinished, setCountdownFinished] = useState(false)

  const { code } = useParams()


  useEffect(() => {
    setTime(dayjs(new Date()).add(5, 'seconds').format('YYYY-MM-DD HH:mm:ss'))

    socket.on('codeAccepted', () => {
      console.log("Code accepted")
      socket.emit('setCode', code)
    })
  }, [])

  return (
    <div className="flex flex-col justify-center mb-10">
      <Navbar showStream={showStream} />
      <Switch>
        <Route path="/admin" exact>
          <Admin />
        </Route>
        <Route path="/">
          <div className="container mx-auto">
            {!countdownFinished && <Timer time={time} finished={setCountdownFinished} />}
            {(countdownFinished && !showStream) && <NameInput code={code} time={time} toggleStream={toggleStream} />}
            {showStream && <Stream />}
          </div>
        </Route>
      </Switch>

      <Cookies />
    </div>
  );
}

export default App;
