import axios from "axios"
import { useEffect, useState } from "react"
import Question from "./question/question"

const Admin = () => {
    const [questions, setQuestions] = useState([])
    const [confirmedQuestions, setConfirmedQuestions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchQuestions = async () => {
            setLoading(true)
            try {
                const { data, status } = await axios.get('questions')
                if (status === 200) {
                    console.log(data)
                    setQuestions(data)
                }
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }

        fetchQuestions()
    }, [])

    const moveToConfirmed = (id) => {
        let questionsCopy = [...questions]
        questionsCopy = questionsCopy.filter(q => q.id !== id)
        setQuestions(questionsCopy)

        let confirmedCopy = [...confirmedQuestions]
        confirmedCopy.push(questionsCopy[0])
        setConfirmedQuestions(confirmedCopy)
    }

    return (
        <div className="container mx-auto px-24 flex">
            <div className="w-2/3 mr-3">
                <h1 className="text-2xl font-medium mt-4">Pytania czekające na zatwierdzenie:</h1>
                <div className="grid grid-cols-2 gap-3 w-full mt-3">
                    {questions.map(q => <Question text={q.text} author={q.author} date={q.createdAt} key={q.id} moveToConfirmed={moveToConfirmed} id={q.id} />)}
                </div>
            </div>
            <div className="w-1/3">
                <h1 className="text-2xl font-medium mt-4">Kolejka pytań:</h1>
                <div className="grid grid-cols-1 gap-3 w-full mt-3">
                    {confirmedQuestions.map(q => <Question text={q.text} author={q.author} date={q.createdAt} />)}
                </div>
            </div>
        </div>
    )
}

export default Admin