import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    token: '',
    name: ''
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        }
    }
})

export const { setToken, setName } = userSlice.actions;

export const getToken = state => state.user.token;
export const getName = state => state.user.name;

export default userSlice.reducer;