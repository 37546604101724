import axios from "axios"
import { useState } from "react"
import { useSelector } from "react-redux"
import { getName } from "../../../app/user/userSlice"
import Button from "../../button/button"

const AskQuestion = ({ toggle, setQuestionSent }) => {
    const name = useSelector(getName)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const sendQuestion = async () => {
        setLoading(true)
        if (!message) {
            setLoading(false)
            return;
        }
        // try {
        //     const { status, data } = await axios.post('questions', {
        //         text: message,
        //         author: name
        //     })
        //     if (status === 200) {
        //         toggle(false)
        //         setQuestionSent(true)
        //     }
        // } catch (error) {
        //     console.log(error)
        // }
        setTimeout(() => {
            toggle(false)
            setQuestionSent(true)
            setLoading(false)

        }, 2000)
    }
    return (
        <div className="container mx-auto mt-16 ">
            <div className="flex justify-center items-center flex-col md:flex-row px-2 md:px-0">
                <span className="text-lg px-6">zapytaj Jurka</span>
                <textarea value={message} onChange={e => setMessage(e.target.value)} name="" id="" cols="30" rows="5" className="bg-gray-200 text-lg px-2 py-2 w-full md:w-96 outline-none resize-none"></textarea>
            </div>
            <Button text="wyślij" action={sendQuestion} value="" loading={loading} />
        </div>
    )
}

export default AskQuestion