import dayjs from "dayjs"

const Question = ({ text, author, date, moveToConfirmed, id }) => {
    return (
        <div className="bg-white w-full px-4 py-4 shadow-md rounded-md border-2 border-gray-100">
            <p className="flex justify-between font-semibold text-gray-400">
                <span>{author}</span>
                <span>{dayjs(date).format('DD-MM-YYYY, hh:mm')}</span>
            </p>
            <p className="mt-2 text-lg">{text}</p>
            <div className="mt-4">
                <button className="text-white bg-green-600 px-3 py-1 rounded-md font-medium hover:bg-green-700 duration-200" onClick={() => moveToConfirmed(id)}>Zatwierdź</button>
                <button className="text-red-500 px-2 py-1 rounded-md hover:bg-gray-100 ml-4 font-medium duration-200">Odrzuć</button>
            </div>
        </div>
    )
}

export default Question