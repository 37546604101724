import mbank_logo from '../../assets/images/mbank_logo.jpg'
import heading_image from '../../assets/images/heading.png'
import heading_jurek from '../../assets/images/heading_jurek.png'

const Navbar = ({ showStream }) => {
    return (
        <div className="inline-block mx-auto">
            <div className="py-6 w-full xl:w-3/5 mx-auto">
                <img src={mbank_logo} alt="mbank_logo" className="pl-6 md:pl-10 xl:pl-0 w-32 sm:w-34 md:36" />
            </div>

            <img src={'.' + heading_jurek} className={`w-full -mt-6 md:-mt-12 xl:-mt-20 ${showStream && 'hidden'}`} alt="banner" />
            <img src={'.' + heading_image} className={`w-full mt-3 ${!showStream && 'hidden'}`} alt="banner" />
            {/* {!showStream && <img src={'.' + heading_jurek} className="w-full -mt-20" alt="banner" />}
            {showStream && <img src={'.' + heading_image} className="w-full mt-3" alt="banner" />} */}
        </div>
    )
}

export default Navbar